<template>
  <div>
    <header>
      <div>
        <h1>Team</h1>
      </div>
    </header>
    <section class="container-md my-40">
      <div>
        <h2 class="header-underline">Major contributions</h2>
        <div class="items">
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/puri_v.webp" alt="" />
            </div>
            <div>
              <h3>Puri Virakarin</h3>
              <div>High School Student</div>
              <div>KVIS</div>
              <div>Email: puri_v@kvis.ac.th</div>
            </div>
          </div>
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/nuttagun_s.webp" alt="" />
            </div>
            <div>
              <h3>Natthakan Saengnil</h3>
              <div>High School Student</div>
              <div>KVIS</div>
              <div>Email: nuttagun_s@kvis.ac.th</div>
            </div>
          </div>
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/bundit_b.webp" alt="" />
            </div>
            <div>
              <h3>Bundit Boonyarit</h3>
              <div>Ph.D. Student</div>
              <div>VISTEC</div>
              <div>Email: bundit.b_s18@vistec.ac.th</div>
            </div>
          </div>
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/jiramet_k.webp" alt="" />
            </div>
            <div>
              <h3>Jiramet Kinchagawat</h3>
              <div>Visiting Researcher</div>
              <div>VISTEC</div>
              <div>Email: jiramet.kin@kaist.ac.kr</div>
            </div>
          </div>
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/rattasat_l.webp" alt="" />
            </div>
            <div>
              <h3>Rattasat Laotaew</h3>
              <div>Developer</div>
              <div>VISTEC</div>
              <div>Email: rattasatl_pro@vistec.ac.th</div>
            </div>
          </div>
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/treephop_s.webp" alt="" />
            </div>
            <div>
              <h3>Treephop Saeteng</h3>
              <div>Developer</div>
              <div>VISTEC</div>
              <div>Email: treephops_pro@vistec.ac.th</div>
            </div>
          </div>
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/thanasan_n.webp" alt="" />
            </div>
            <div>
              <h3>Thanasan Nilsu</h3>
              <div>Teacher</div>
              <div>KVIS</div>
              <div>Email: thanasan.n@kvis.ac.th</div>
            </div>
          </div>
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/naravut_s.webp" alt="" />
            </div>
            <div>
              <h3>Naravut Suvannang</h3>
              <div>Postdoctoral Researcher</div>
              <div>VISTEC</div>
              <div>Email: naravuts_pro@vistec.ac.th</div>
            </div>
          </div>
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/thanyada_r.webp" alt="" />
            </div>
            <div>
              <h3>Thanyada Rungrotmongkol</h3>
              <div>Assistant Professor</div>
              <div>Chulalongkorn University</div>
              <div>Email: thanyada.r@chula.ac.th</div>
            </div>
          </div>
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/sarana_n.webp" alt="" />
            </div>
            <div>
              <h3>Sarana Nutanong</h3>
              <div>Associate Professor</div>
              <div>VISTEC</div>
              <div>Email: snutanon@vistec.ac.th</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2 class="header-underline">Minor contributions</h2>
        <div class="items">
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/kasidit_p.webp" alt="" />
            </div>
            <div>
              <h3>Kasidit Phoncharoen</h3>
              <div>Developer</div>
              <div>VISTEC</div>
              <div>Email: kasiditp_pro@vistec.ac.th</div>
            </div>
          </div>
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/benjapol_w.webp" alt="" />
            </div>
            <div>
              <h3>Benjapol Worakan</h3>
              <div>Developer</div>
              <div>VISTEC</div>
              <div>Email: benjapolw_pro@vistec.ac.th</div>
            </div>
          </div>
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/chanotai_k.webp" alt="" />
            </div>
            <div>
              <h3>Chanotai Krajeam</h3>
              <div>High School Student</div>
              <div>SPSM</div>
              <div>Email: boom9387@hotmail.com</div>
            </div>
          </div>
          <div class="item">
            <div>
              <v-img class="img" src="../assets/team/alyssa_p.webp" alt="" />
            </div>
            <div>
              <h3>Alyssa Phiromrat</h3>
              <div>High School Student</div>
              <div>SPSM</div>
              <div>Email: mylukbua@gmail.com</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "team",
  components: {
    //
  },
  methods: {},
  props: {
    setActive: Function
  },
  created() {
    this.setActive(this.$route.name.toLowerCase());
  }
};
</script>

<style scoped>
header::before {
  background-image: url("../assets/images/DSCF3176-2.webp");
}

h2 {
  color: #582e91;
}
.img {
  margin: 0 auto;
  width: 80%;
}
.items {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 20px;
  margin: 30px 20px;
}
.item {
  text-align: center;
  color: #582e91;
}
.item > div:last-child {
  margin: 20px 0px;
}

@media screen and (max-width: 991px) {
  .items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 575px) {
  h2 {
    text-align: center;
  }
  .items {
    grid-template-columns: minmax(0, 1fr);
  }
}
</style>
